import React, { FC, useEffect, useState } from 'react';
import { ILedger } from 'services/api/transactions/types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import { shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { roundingNumber } from '../../../services/utils/roundingNumber';

export interface ILedgerTableRow {
	data: ILedger;
}

const LedgerTableRow: FC<ILedgerTableRow> = ({ data }) => {
	const currency = useSelector(getCurrencyData);
	const { url } = useRouteMatch();
	const [asset, setAsset] = useState<ICurrencyDataItem>();

	useEffect(() => {
		setAsset(currency.filter((item) => item.code === data.currency)[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleState = (type: string, id: number, userId: number) => {
		switch (type) {
			case 'trade':
				return {
					fromLedgerTrade: true,
					fromLedgerReports: true,

					txId: id,
					userId,
				};
			case 'deposit':
				if (asset?.type === 'crypto' || asset?.type === 'token') {
					return {
						fromLedgerDepositCrypto: true,
						fromLedgerReports: true,

						txId: id,
						userId,
					};
				}
				return {
					fromLedgerDepositFiat: true,
					fromLedgerReports: true,
					txId: id,
					userId,
				};
			case 'withdrawal':
				if (asset?.type === 'crypto' || asset?.type === 'token') {
					return {
						fromLedgerWithdrawalCrypto: true,
						fromLedgerReports: true,
						txId: id,
						userId,
					};
				}
				return {
					fromLedgerWithdrawalFiat: true,
					fromLedgerReports: true,
					txId: id,
					userId,
				};
			default:
				return {};
		}
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{moment.utc(data.updated_at).format('YYYY-MM-DD')}</p>
				<span className="td-more-info">{moment.utc(data.updated_at).format('HH:mm:ss')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{`${data.type.charAt(0).toUpperCase()}${data.type.slice(1)}`}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{data.currency.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Debit</p>
				<p>{data.debit ? roundingNumber(data.debit, data.currency) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Credit</p>
				<p>{data.credit ? roundingNumber(data.credit, data.currency) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{roundingNumber(data.fee, data.currency)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Credit Fee</p>
				<p>{data.credit_fee ? roundingNumber(data.credit_fee, data.currency) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Debit Fee</p>
				<p>{data.debit_fee ? roundingNumber(data.debit_fee, data.currency) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{roundingNumber(data.net, data.currency)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>
				<p>{roundingNumber(data.balance, data.currency)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				{data.destination.length > 30 && asset?.type !== 'fiat' ? (
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(data.destination, 4, 4) || '-'}
						</span>

						{data.destination && (
							<CopyToClipboard text={data.destination.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M2.92578 12.8151C2.69245 12.8151 2.48828 12.7276 2.31328 12.5526C2.13828 12.3776 2.05078 12.1734 2.05078 11.9401V3.14635H2.92578V11.9401H9.83828V12.8151H2.92578ZM4.67578 11.0651C4.44245 11.0651 4.23828 10.9776 4.06328 10.8026C3.88828 10.6276 3.80078 10.4234 3.80078 10.1901V2.02344C3.80078 1.7901 3.88828 1.58594 4.06328 1.41094C4.23828 1.23594 4.44245 1.14844 4.67578 1.14844H11.0924C11.3258 1.14844 11.5299 1.23594 11.7049 1.41094C11.8799 1.58594 11.9674 1.7901 11.9674 2.02344V10.1901C11.9674 10.4234 11.8799 10.6276 11.7049 10.8026C11.5299 10.9776 11.3258 11.0651 11.0924 11.0651H4.67578ZM4.67578 10.1901H11.0924V2.02344H4.67578V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				) : (
					<>
						<p className="copy-button">{data.destination}</p>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Reference</p>

				{data.reference && (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">
							{data.reference.length > 10 ? shortNotation(data.reference, 4, 4) : data.reference}
						</p>
						{data.reference.length > 10 && (
							<div className="tooltip-note">
								<p>{data.reference}</p>
								<i />
							</div>
						)}
					</div>
				)}
				{!data.reference && data.ext_tx_hash && (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">
							{data.ext_tx_hash.length > 10
								? shortNotation(data.ext_tx_hash, 4, 4)
								: data.ext_tx_hash}
						</p>
						{data.ext_tx_hash.length > 10 && (
							<div className="tooltip-note">
								<p>{data.ext_tx_hash}</p>
								<i />
							</div>
						)}
					</div>
				)}
				{!data.reference && !data.ext_tx_hash && (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">-</p>
					</div>
				)}
				{/* <p>{data.reference ? data.reference : '-'}</p> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				<Link
					to={{
						pathname: `${url}/${data.tx_id}`,
						state: handleState(data.type, data.tx_id, data.user_id),
					}}
				>
					{data.tx_id}
				</Link>
				{/* <p>{data.tx_id}</p> */}
			</div>
		</div>
	);
};

export default LedgerTableRow;
